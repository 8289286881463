// i18next-extract-mark-ns-start products-vectis

import { LINKS, VECTIS_NAV, VECTIS_PUBLICATIONS_NAV } from '@utils/constants';
import { graphql } from 'gatsby';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import React from 'react';

import Aside from '@components/Aside';
import AssetList from '@components/AssetList';
import Banner from '@components/Banner';
import Breadcrumbs, { Breadcrumb } from '@components/Breadcrumbs';
import Button from '@components/Button';
import ContactForm from '@components/ContactForm';
import PageSection from '@components/PageSection';
import Page from '@components/layout/Page';
import SEO from '@components/layout/SEO';
import ResourceListing from '@components/views/ResourceListing';
import { Link, Trans, useTranslation } from 'gatsby-plugin-react-i18next';

const ProductVectisPage = ({
	pageContext: {
		breadcrumb: { crumbs },
	},
	data
}: {
	pageContext: {
		breadcrumb: { crumbs: Breadcrumb[] };
	};
	data: any;
}) => {
	const { t } = useTranslation();

	const icon = getImage(data.icon.childImageSharp.gatsbyImageData);
	const image = getImage(data.image.childImageSharp.gatsbyImageData);

	return (
		<Page>
			<SEO
				title={t("seo_title")}
				description={t("seo_description")}
			/>

			<Banner
				title={t("title")}
				image={{
					src: data.banner.childImageSharp.gatsbyImageData,
					alt: '',
				}}
				size="standard"
			/>

			<Breadcrumbs crumbs={crumbs} />

			<PageSection
				className="section__content section__content--dual"
				layout="none">
				<div className="col xl7 m12 m-spacer page__content page__content--dual">
					{icon && (
						<div className="product-lead-icon image-contain">
							<GatsbyImage
								image={icon}
								alt="VECTIS"
								className="icon-image"
							/>
						</div>
					)}
					<Trans i18nKey="content_1">
						<p>
							VECTIS is a comprehensive CFD software solution empowering internal combustion engine designers to
							tackle their most pressing challenges. Our goal is to provide you with the tools you need to develop
							more efficient and environmentally friendly powertrains. With VECTIS, you will enjoy a seamless and
							intuitive experience from start to finish when developing engines for any applications in motorcycle,
							light-duty, heavy duty, off-road, marine, power generation, aerospace and other industries.
						</p>

						<h2>Key features</h2>

						<p>
							For two-stroke, four-stroke, rotary, single or multi-cylinder simulations our pre-processing suite
							simplifies your workflow, allowing you to focus on what matters most – achieving exceptional results.
						</p>
					</Trans>

					{image && (
						<p>
							<GatsbyImage image={image} alt="VECTIS Multi-Cylinder TSI Combustion" />
						</p>
					)}

					<Trans i18nKey="content_2">
						<ul>
							<li>
								Our software features cutting-edge kinetics, combustion and emissions
								models specifically tailored for e-fuels, synthetic and non-carbon fuels. This
								enables you to stay at the forefront of innovation and create greener powertrain solutions.
							</li>
							<li>
								The uniqueness of VECTIS lies not only in the accurate modelling
								capabilities and optimized workflows, but also in our commitment to
								providing personalized support and assistance.
							</li>
							<li>
								We understand that each customer journey is unique, which is why we offer
								custom model implementation, tailored workflow development, and
								dedicated support. Our goal is to help you maximize the benefits of virtual
								product development while minimizing costs.
							</li>
							<li>
								Join the ranks of industry leaders who have already embraced VECTIS to
								revolutionize their engineering workflows. Together, let's take your
								engineering efforts to new heights with VECTIS.
							</li>
						</ul>

						<h2>Bespoke user model development and expert systems consultancy</h2>

						<p>
							At Realis, we understand that each customer is unique and we are offering a close partnership helping our customers
							build tailored solutions and processes reflecting their workflows and maximizing efficiency of their CAE process.
							We put our customers in the centre of decision making by offering :
						</p>
						<ul>
							<li>Direct interaction with product and development teams through customer driven product development</li>
							<li>Bespoke model development and rapid enhancement development based on customer feedback</li>
							<li>Early access to requested features through customized versions</li>
							<li>Dedicated and tailored support, customized interactive training</li>
							<li>Tailored licensing solutions</li>
							<li>Process and model building consulting services</li>
						</ul>
					</Trans>
				</div>
				<Aside>
					<Aside.Widget
						title={t("_CapabilitiesApplications")}
						className="widget__sibling__pages">
						<AssetList>
							{VECTIS_NAV.map((item, i) => (
								<Link
									key={i}
									to={item.value}
									className="assets__link"
									activeClassName="active">
									{t(item.labelkey) /* i18next-extract-disable-line */}
								</Link>
							))}
						</AssetList>
					</Aside.Widget>
					<Aside.Widget
						title={t("_Publications")}
						className="widget__sibling__pages">
						<AssetList>
							{VECTIS_PUBLICATIONS_NAV.map((item, i) => (
								<Link
									key={i}
									to={item.value}
									className="assets__link"
									activeClassName="active">
									{t(item.labelkey) /* i18next-extract-disable-line */}
								</Link>
							))}
						</AssetList>
					</Aside.Widget>
					<Link
						to={LINKS.ABOUT_CONTACT}
						className="button--more">
						{t("_GetInTouch")}
					</Link>
				</Aside>
			</PageSection>

			<ResourceListing
				resources={data.resources}
				title={t("_OurInsights")}
				layout="fullwidth"
				actions={
					<div className="text--center">
						<Button to={LINKS.INSIGHTS} type="more">
							{t("_SeeAllInsights")}
						</Button>
					</div>
				}
			/>

		</Page>
	);
};

export default ProductVectisPage;

export const pageQuery = graphql`
query ($language: String!) {
	locales: allLocale(
		filter: { ns: { in: ["products-vectis", "_common"] }, language: { eq: $language } }
	) {
		edges {
			node {
				ns
				data
				language
			}
		}
	}
	banner: file(relativePath: { eq: "content/products/vectis/Realis-Simulation_VECTIS.jpg" }) {
		...imageBreaker
	}
	icon: file(relativePath: { eq: "content/products/vectis/rs_vectis.png" }) {
		...imageBreaker
	}
	image: file(relativePath: { eq: "content/products/vectis/Realis-Simulation_VECTIS_TSI_Multicylinder.png" }) {
		...imageBreaker
	}
	resources: allMarkdownRemark(
		filter: {
			fileAbsolutePath: { regex: "/resources/" }
			fields: { language: { eq: $language } }
			frontmatter: { products: { in: "VECTIS" } }
		}
		sort: { fields: frontmatter___date, order: DESC }
		limit: 6
	) {
		nodes {
			fields {
				slug
			}
			frontmatter {
				title
				summary
				image {
					...imageStandard
				}
				type
			}
		}
	}
}
`;

